import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { Route } from 'react-router-dom'
import App from './App'
import JSONLoader from './components/JSONLoader'
import ThankYouContainer from '../House/containers/ThankYouContainer'
import { ConnectedRouter } from 'connected-react-router/immutable'
import { history } from './store/configureStore'
import '../stylesheets/index.scss'

const Root = ({ store }) => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Fragment>
        <JSONLoader />
        {/* Path of home App */}
        <Route exact path="/" component={App} />
        {/* Path to display upon successful form submission */}
        <Route exact path="/success" component={ThankYouContainer} />
      </Fragment>
    </ConnectedRouter>
  </Provider>
)

Root.propTypes = {
  store: PropTypes.object.isRequired,
}
export default Root
