import React, { Component } from 'react'
import parse from 'html-react-parser'

export default class TitleBody extends Component {

  // function for displaying Title
  displayTitle() {
    if (this.props.title) {
      return <h2>{parse(this.props.title)}</h2>
    }
  }

  // function for displaying Subtitle
  displaySubtitle() {
    if (this.props.subtitle) {
      return <h3>{this.props.subtitle}</h3>
    }
  }

  // function for displaying Body
  displayBody() {
    if (this.props.body) {
      return <p>{this.props.body}</p>
    }
  }

  render() {
    return(
      <div className="title-body">
        {this.displayTitle()}
        {this.displaySubtitle()}
        {this.displayBody()}
      </div>
    )
  }
}

