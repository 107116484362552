import React, { Component } from 'react'
import * as ActionCreators from '../../House/ActionCreators'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import * as utils from '../../Utilities/Utils'

var validator = require("email-validator")

class CtaButton extends Component {

  constructor(props) {
    super(props)
    this.JSONObj = null
  }

  buttonNavClick() {
    this.props.dispatchSetScreen(this.props.screen)
  }

  buttonReset() {
    this.props.dispatchResetZoom(0)
    this.props.resetTransformHook()
    setTimeout(() => {
      this.props.dispatchShowIcons(true)
    }, 550 )
  }

  // function to validate email on a form submit button
  emailValidate(e) {
    e.preventDefault()
    const email = document.getElementById('emailInput').value
    // create a const that is a copy of the json object in state
    const JSONTemp = utils.getJSON(this.props.json, this.JSONObj)
    if (JSONTemp !== null) {
      if (validator.validate(email) !== true) {
        document.getElementById('emailError').innerHTML = JSONTemp.screens.HOUSE_LIST_VIEW.left_column.form.error_message
      } else document.getElementById('submit-form').submit()
    } 
  }

  render() {

    // check to see which type of button is displayed via passed prop
    const displayButton = () => {
      if (this.props.buttonType === 'navigation') {
        return(
          <button
            className={this.props.buttonClass}
            onClick={this.buttonNavClick.bind(this)}>{this.props.text}
          </button>
        )
      } else if (this.props.buttonType === 'reset') {
        return(
          <button
            className={this.props.buttonClass}
            onClick={this.buttonReset.bind(this)}>{this.props.text}
          </button>
        )
      } else if (this.props.buttonType === 'submit') {
        return(
          <button
            onClick={this.emailValidate.bind(this)}
            className={this.props.buttonClass}>{this.props.text}
          </button>
        )
      }
    }

    return(displayButton())
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    resetTransformHook: state.getIn(['house', 'resetTransformHook']),
    json: state.getIn(['house', 'json'])
  }
}

const mapDispatchToProps = dispatch => {
  return {
      dispatchSetScreen: (screen) => dispatch(ActionCreators.setScreen(screen)),
      dispatchResetZoom: (num) => dispatch(ActionCreators.resetZoom(num)),
      dispatchShowIcons: (boolean) => dispatch(ActionCreators.showIcons(boolean))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CtaButton))