import * as ActionTypes from './ActionTypes'

// function to set current screen in state
export function setScreen(screen) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.HOUSE_SET_SCREEN,
      screen: screen
    })
  }
}

// function to add icon to a map if active in state
export function addIcon(prevState, icon) {
  return(dispatch) => {
    dispatch({
      type: ActionTypes.SET_ACTIVE_ICON,
      icons: prevState.set(icon, true)
    })
  }
}

// function to set which icon was clicked in state
export function setClicked(icon) {
  return(dispatch) => {
    dispatch({
      type: ActionTypes.LOAD_ICON_DETAIL,
      icon: icon
    })
  }
}

// function to show is showIcons is true or false in state
export function showIcons(boolean) {
  return(dispatch) => {
    return Promise.resolve(
      dispatch({
        type: ActionTypes.SHOW_ICONS,
        boolean: boolean
      })
    )
  }
}

// function to show if showModal is true or false in state
export function showModal(boolean) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.SHOW_MODAL,
      boolean: boolean
    })
  }
}

// action to reset zoom state
export function resetZoom(num) {
  return(dispatch) => {
    dispatch({
      type: ActionTypes.RESET_ZOOM,
      num: num
    })
  }
}

export function setResetTransformHook(hook) {
  return(dispatch) => {
    dispatch({
      type: ActionTypes.SET_RESET_TRANSFORM_HOOK,
      hook: hook
    })
  }
}

// load JSON data into state
export function loadJSON(data) {
  return(dispatch) => {
    dispatch({
      type: ActionTypes.SET_JSON_DATA,
      data: data
    })
  }
}