import React, { Component } from 'react'
import Logo from '../../images/Eversourcelogo_color.svg'

export default class Loader extends Component {

  render() {

    const displayClass = () => {
      if(this.props.load) {
        return 'full-column-loader animate-out-loader'
      } 
      return 'full-column-loader'
    }

    const style = this.props.load ? {transition: `visibility 0s linear ${this.props.visibility}, opacity ${this.props.opacity}`} : null

    return(
      <div 
        className={displayClass()}
        style={style}
      >
        <img src={Logo} alt=""/>
        <div className="sk-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
    )
  }
} 