import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import LeftColumn from '../../App/containers/LeftColumn'
import RightColumn from '../../App/containers/RightColumn'
import LeftColumnInnerContainer from './LeftColumnInnerContainer'
import HouseClosed from '../../images/Background-House_Closed.svg'
import Loader from '../../App/components/Loader'
import * as utils from '../../Utilities/Utils'

class MainViewContainer extends Component {

  constructor(props) {
    super(props)
    this.JSONObj = null
    this.state = {
      componentLoaded: false
    }
  }

  componentDidMount() {
    setTimeout(function() {
      this.setState({
        componentLoaded: true
      })
    }.bind(this), 500)
  }

  render() {

    // create a const that is a copy of the json object in state
    const JSONTemp = utils.getJSON(this.props.json, this.JSONObj)

    const renderParticleSystems = () => {
      if (JSONTemp !== null) {
        const screen = JSONTemp.screens.HOUSE_MAIN_VIEW
        if (screen.particle_systems) {
          return utils.renderParticleSystems(screen.particle_systems)
        }
      }
    }

    const renderStateLogo = () => {
      if (JSONTemp !== null) {
        const logo = JSONTemp.global_variables
        const findState = logo.states[logo.default_state]
        return utils.renderStateLogo(findState)
      }
    }

    const renderImage = () => {
      if (JSONTemp !== null) {
        const screen = JSONTemp.global_variables.logo_white
        return utils.renderImage(screen, 'logo')
      }
    }

    const renderLoader = () => {
      if(JSONTemp !== null) {
        const delay = JSONTemp.global_variables
        return (
          <Loader 
            load={this.state.componentLoaded} 
            opacity={delay.load_screen_fade_opacity_delay}
            visibility={delay.load_screen_fade_visibility_delay} 
          />
        )
      }
    }
    
    return (
      <div className="full-height-columns">
        <LeftColumn>
          <div>
            <div className="logo-state-container">{renderImage()}{renderStateLogo()}</div>
            <LeftColumnInnerContainer />
          </div>
        </LeftColumn>
        <RightColumn>
          {renderLoader()}
          <div className="right-column-house-container">
            {renderParticleSystems()}
            <img className="closed-house" src={HouseClosed} alt=""/>
          </div>
        </RightColumn>
      </div> 
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    json: state.getIn(['house', 'json'])
  }
}

const mapDispatchToProps = dispatch => {
  return {

  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainViewContainer))