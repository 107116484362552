import React, { Component } from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import * as utils from '../../Utilities/Utils'
import VideoThumbnail from '../../App/components/VideoThumbnail'
import VideoModal from '../../House/components/VideoModal'

var uuid = require('uuid/v4')

class DetailScreenContentLeft extends Component {

  constructor(props) {
    super(props)
    this.JSONObj = null
  }

  render() {

    // create a const that is a copy of the json object in state
    const JSONTemp = utils.getJSON(this.props.json, this.JSONObj)

    // return array of items corresponding to the 
    const renderIconDetail = () => {
      if (JSONTemp !== null) {
        const iconObj = JSONTemp.screens[this.props.clickedIcon].left_column
        console.log(iconObj)
        return [
          utils.renderTitleBody(iconObj.title),
          <VideoThumbnail key={uuid()} thumbnail={iconObj.thumbnail} />,
          <VideoModal key={uuid()} url={iconObj.video} />,
          utils.renderTitleBody(null, iconObj.subtitle, iconObj.body),
          utils.renderCtaButton(iconObj.button)
        ] 
      }
    }

    return(
      <div>
        {renderIconDetail()}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    activeIcons: state.getIn(['house', 'activeIcons']),
    json: state.getIn(['house', 'json']),
    clickedIcon: state.getIn(['house', 'clickedIcon'])
  }
}

const mapDispatchToProps = dispatch => {
  return {
    
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DetailScreenContentLeft))
