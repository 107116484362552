import React, { Component } from 'react'

export default class TapIconMessage extends Component {

  constructor(props) {
    super(props)
    this.timerIn = null
    this.timerOut = null
  }
  
  componentDidMount() {
    // show element after setTimeout, then hide element
    if (document.getElementById('tapMessage') !== null) {
      this.timerIn = setTimeout(() => {
        document.getElementById('tapMessage').style.opacity = "100%"
      }, this.props.customData.fadeinTime)
      this.timerOut = setTimeout(() => {
        document.getElementById('tapMessage').style.opacity = "0%"
      }, this.props.customData.fadeoutTime)
    }
  }

  componentWillUnmount() {
    // clear timers on component unmount
    clearTimeout(this.timerIn)
    clearTimeout(this.timerOut)
  }

  render() {

    const style = {transform: `translate(${this.props.customData.transform_position.x}px, ${this.props.customData.transform_position.y}px)`}

    return(
      <div id="tapMessage" className="tap-icon-message-container" style={style}>
        <div className="d-flex justify-content-center">
          <img src={this.props.icon} alt=""/>
          <p>{this.props.text}</p>
        </div>
      </div>
    )
  }
}
