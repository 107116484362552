import React, { Component } from 'react'
import * as ActionCreators from '../../House/ActionCreators'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import * as config from '../../App/configurations/Configs'

class VideoThumbnail extends Component {

  displayModal = () => {
    this.props.dispatchShowModal(true)
  }

  render() {

    return(
      <img 
        onClick={this.displayModal}
        src={config.DATA.HOST + 'images/' + this.props.thumbnail} 
        alt=""
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {

  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatchShowModal: (boolean) => dispatch(ActionCreators.showModal(boolean))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VideoThumbnail))