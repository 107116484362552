import React, {Component} from 'react'

export default class LeftColumn extends Component {

    render() {
        return (
            <div className="left-column-container">
                {this.props.children}
            </div>
        )
    }
}