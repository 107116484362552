import React, {Component} from 'react'
import House from '../../images/Background-House_Closed.svg'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import * as utils from '../../Utilities/Utils'

class StartScreenContainer extends Component {

    constructor(props) {
        super(props)
        this.JSONObj = null
    }

    render() {

    // create a const that is a copy of the json object in state
    const JSONTemp = utils.getJSON(this.props.json, this.JSONObj)

        const renderParticleSystems = () => {
            if (JSONTemp !== null) {
                const screen = JSONTemp.screens.START_SCREEN
                if (screen.particle_systems) {
                    return utils.renderParticleSystems(screen.particle_systems)
                }
            }
        }

        const renderInsideContentContainer = () => {
            if (JSONTemp !== null) {
                const screen = JSONTemp.screens.START_SCREEN
                return [
                    utils.renderImage(JSONTemp.global_variables.logo_color, 'logo'),
                    utils.renderTitle(screen.title),
                    utils.renderCtaButton(screen.button)
                ]
            }
        }

        return (
            <div className="startscreen-wrapper">
                {renderParticleSystems()}
                <img className="start-house" src={House} alt=""/>
                <div className="startscreen-content-container">
                    {renderInsideContentContainer()}
                </div>
            </div>    
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        json: state.getIn(['house', 'json'])
    }
}


const mapDispatchToProps = dispatch => {
    return {

    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StartScreenContainer))