import React, { Component } from 'react'
import * as ActionCreators from '../ActionCreators'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import * as Enums from '../Enums'
import parse from 'html-react-parser'

class ListIconDescription extends Component {

  // function to set new screen
  buttonNavClick() {
    this.props.dispatchSetScreen(Enums.HOUSE_INNER_VIEW)
  }

  render() {
    if(!this.props.activeIcons.has(this.props.iconKey)) {
      return(
        <div className="learn-more-wrapper d-flex flex-column justify-content-center">
          <h5>{this.props.iconDescription}</h5>
          <button onClick={this.buttonNavClick.bind(this)}>{parse(this.props.subtitle)}</button>
        </div>
      )
    } else {
        return (
          <h5>{this.props.iconDescription}</h5>
        ) 
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    activeIcons: state.getIn(['house', 'activeIcons'])
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatchSetScreen: (screen) => dispatch(ActionCreators.setScreen(screen))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListIconDescription))