/* eslint-disable no-undef */

import React, {Component} from 'react'
// import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import Footer from './containers/Footer'
import Body from './containers/Body'
import Container from 'react-bootstrap/Container'
import SplashScreen from './components/SplashScreen'
import * as utils from '../Utilities/Utils'

class App extends Component {
  static propTypes = {
    // Injected by React Redux Injected by React Router
  }

  constructor(props) {
    super(props)
    this.JSONObj = null
    this.state = {
      containerLoaded: false
    }
  }

  componentDidMount() {
    // update container loaded state once window is done loading
    window.addEventListener("load", () => {
      this.setState({
        containerLoaded: true
      })
    })
  }

  render() {

    // create a const that is a copy of the json object in state
    const JSONTemp = utils.getJSON(this.props.json, this.JSONObj)

    // render splash screen until window is done loading
    const renderSplashScreen = () => {
      if(JSONTemp !== null) {
        const delay = JSONTemp.global_variables
        return (
          <SplashScreen 
            load={this.state.containerLoaded} 
            delay={delay.load_screen_fade_delay} 
          />
        )
      }
    }

    return (
      <Container fluid={true} className="px-0">
        {renderSplashScreen()}
        <Body />
        <Footer />
      </Container>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  json: state.getIn(['house', 'json'])
})

export default withRouter(connect(mapStateToProps, {
    
})(App))
