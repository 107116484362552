import React from 'react'
import ParticleAnimation from '../App/components/ParticleAnimation'
import CtaButton from '../App/components/CtaButton'
import StateLogo from '../House/components/StateLogo'
import parse from 'html-react-parser'
import * as config from '../App/configurations/Configs'
import TitleBody from '../App/components/TitleBody'
import HouseIcon from '../House/components/HouseIcon'
import StateIcon from '../House/components/StateIcon'
import TapIconMessage from '../House/components/TapIconMessage'
import ListIconDescription from '../House/components/ListIconDescription'
import Form from '../House/components/Form'
import ThankYouContainer from '../House/containers/ThankYouContainer'

var uuid = require('uuid/v4')

// function to render particle systems with data from JSON
export const renderParticleSystems = (particleSystem) => {
  var elements = []
  particleSystem.forEach((ps) => {
    const e = 
      <ParticleAnimation 
        key={uuid()}
        yMin={ps.yMin}
        yMax={ps.yMax}
        spawnTimeMin={ps.spawnTimeMin}
        spawnTimeMax={ps.spawnTimeMax}
        durationMin={ps.durationMin}
        durationMax={ps.durationMax}
        sizes={ps.sizes}
        images={ps.images}
        direction={ps.direction ? ps.direction : 'both'}
      />
      elements.push(e)
  })
  return elements
}

// function to render CTA button with data from JSON
export const renderCtaButton = (button) => {
  return (
    <CtaButton
      key={uuid()} 
      text={parse(button.text)}
      buttonClass={button.class}
      buttonType={button.action.type}
      screen={button.action.screen}
    />
  )
}

// function to render image with data from JSON
export const renderImage = (image, imgClass) => {
  return (
    <img key={uuid()} className={imgClass} src={config.DATA.HOST + 'images/' + image} alt=""/>
  )
}

// function to render title with data from JSON
export const renderTitle = (title) => {
  return (
    <h1 key={uuid()}>{parse(title)}</h1>
  )
}

// function to render title, subtitle, and body with data from JSON
export const renderTitleBody = (title, subtitle, body) => {
  return (
    <TitleBody
      key={uuid()} 
      title={title}
      subtitle={subtitle}
      body={body}
    />
  )
}

// function to render state logo component with data from JSON
export const renderStateLogo = (state) => {
  return (
    <StateLogo 
      name={state.abbreviation} 
      logo={state.icon}  
    />
  )
}

// function to render state icon component with data from JSON
export const renderStateIcon = (state) => {
  return (
    <StateIcon 
      text={state.text}
      image={state.icon}
    />
  )
}

// function to render input form with data from JSON
export const renderInputForm = (data) => {
  return (
    <Form 
      title={data.title}
      formAction={data.formAction}
      _clientID={data._clientID}
      _deExternalKey={data._deExternalKey}
      _action={data._action}
      _returnXML={data._returnXML}
      _successURL={data._successURL}
      _errorURL={data._errorURL}
      placeholder={data.placeholder}
    />
  )
}

// function to render dropdown message with data from JSON
export const renderDropdown = (content) => {
  return (
    <TapIconMessage 
      icon={config.DATA.HOST + 'images/' + content.icon}
      text={parse(content.text)}
      customData={content}
    />
  )
}

// function to get column height. Used in renderHouseIcon function
const getColumnHeight = (offset) => {
  let zoomHeightLocation = null
  if (window.innerWidth <= 1700) {
    zoomHeightLocation = (offset * 4.5) * .67
  } else zoomHeightLocation = (offset * 4.5)
  return zoomHeightLocation
}

// function to get column width. Used in renderHouseIcon function
const getColumnWidth = (offset) => {
  let zoomWidthLocation = null
  if (window.innerWidth <= 1700) {
    zoomWidthLocation = (offset * 4.5) * .67
  } else zoomWidthLocation = (offset * 4.5)
  return zoomWidthLocation
}

// function to get render house icon with data from JSON, passing 2 functions from the anime.js library
export const renderHouseIcon = (key, icon, setTransform, resetTransform, dispatchSetResetTransformHook, globalVar) => {
  return (
    <HouseIcon 
      key={uuid()}
      iconKey={key}
      iconClass={icon.class}
      blueIcon={config.DATA.HOST + 'images/' + icon.blue_icon}
      greenIcon={config.DATA.HOST + 'images/' + icon.green_icon}
      zoomLocation={()=>{
          dispatchSetResetTransformHook(resetTransform)
          setTransform(getColumnWidth(icon.setTransform.xOffset), getColumnHeight(icon.setTransform.yOffset), globalVar.zoom, globalVar.zoomSpeed, globalVar.easing)
      }}
      xTransform={icon.icon_transform_position.x}
      yTransform={icon.icon_transform_position.y}
    />
  )
}

// function to get render house icon with data from JSON
export const renderListHouseIcon = (key, icon) => {
  return (
    <HouseIcon 
      key={uuid()}
      iconKey={key}
      iconClass={icon.class}
      blueIcon={config.DATA.HOST + 'images/' + icon.blue_icon}
      greenIcon={config.DATA.HOST + 'images/' + icon.green_icon}
    />
  )
}

// function to render list description
export const renderListIconDescription = (key, icon) => {
  return (
    <ListIconDescription 
      key={uuid()}
      iconDescription={icon.detail.title}
      subtitle={icon.detail.subtitle}
      iconKey={key}
    />
  )
}

// function to render thank you screen
export const renderThankYouContainer = (data) => {
  return (
    <ThankYouContainer 
      title={data.thank_you_title}
      body={data.thank_you_body}
      icon={data.icon}
    />
  ) 
} 

// function to create a copy of an immutable map
export const getJSON = (immutableJSON, JSONObj) => {
  if (JSONObj === null) {
      if (!immutableJSON.isEmpty()) {
          JSONObj = immutableJSON.toJSON()
          return JSONObj
      }
  } 
  return JSONObj
}
