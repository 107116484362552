import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import * as utils from '../../Utilities/Utils'

class ThankYouContainer extends Component {

  constructor(props) {
    super(props)
    this.JSONObj = null
  }

  resetApp() {
    window.location.href="/"
  }

  render() {

    // create a const that is a copy of the json object in state
    const JSONTemp = utils.getJSON(this.props.json, this.JSONObj)

    const renderThankYouIcon = () => {
      if (JSONTemp !== null) {
        const screen = JSONTemp.global_variables.thank_you
        return utils.renderImage(screen.icon)
      }
    }

    const renderLogo = () => {
      if (JSONTemp !== null) {
        const screen = JSONTemp.global_variables
        return utils.renderImage(screen.logo_white)
      }
    }

    const renderTitle = () => {
      if (JSONTemp !== null) {
        const screen = JSONTemp.global_variables.thank_you
        return utils.renderTitle(screen.title)
      }
    }

    const renderBody = () => {
      if (JSONTemp !== null) {
        const screen = JSONTemp.global_variables.thank_you
        return utils.renderTitleBody(null, null, screen.body)
      }
    }

    return (
      <Fragment>
        <div className="thankyou-wrapper">
          <div>
            <div onClick={this.resetApp.bind(this)} className="close-btn">X</div>
          </div>
          <div className="ty-message-container">
            {renderThankYouIcon()}
            {renderTitle()}
            {renderBody()}
          </div>
          <div className="logo">
            {renderLogo()}
          </div>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
    return {
      json: state.getIn(['house', 'json'])
    }
}

const mapDispatchToProps = dispatch => {
    return {

    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ThankYouContainer))