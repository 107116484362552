import React, { Component } from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import * as utils from '../../Utilities/Utils'

class StateSelectContainer extends Component {

  constructor(props) {
    super(props)
    this.JSONObj = null
  }

  render() {

    // create a const that is a copy of the json object in state
    const JSONTemp = utils.getJSON(this.props.json, this.JSONObj)

    const renderTitle = () => {
      if (JSONTemp !== null) {
        const screen = JSONTemp.screens.STATE_SELECT
        return utils.renderTitle(screen.title)
      }
    }

    const renderBody = () => {
      if (JSONTemp !== null) {
        const screen = JSONTemp.screens.STATE_SELECT
        return utils.renderTitleBody(null, null, screen.body)
      }
    }

    const renderLogo = () => {
      if (JSONTemp !== null) {
        const screen = JSONTemp.global_variables
        return utils.renderImage(screen.logo_color, 'logo')
      }
    }

    const renderCtaButton = () => {
      if (JSONTemp !== null) {
        const screen = JSONTemp.screens.STATE_SELECT
        return utils.renderCtaButton(screen.button)
      }
    }

    const renderStateIcons = () => {
      if (JSONTemp !== null) {
        const screen = JSONTemp.global_variables.states
        const iconsArray = Object.values(screen)
        return iconsArray.map((value) => {
          return utils.renderStateIcon(value)
        })
      }
    }

    return(
      <div className="state-select-wrapper">
        <div>
          {renderLogo()}
          {renderTitle()}
          {renderBody()}
          <div className="d-flex">
            {renderStateIcons()}
          </div>
        </div>
        {renderCtaButton()}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    json: state.getIn(['house', 'json'])
  }
}

const mapDispatchToProps = dispatch => {
  return {

  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StateSelectContainer))

