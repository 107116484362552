import React, { Component } from 'react'
import Logo from '../../images/Eversourcelogo_color.svg'

export default class SplashScreen extends Component {

  render() {

    const displayClass = () => {
      if(this.props.load) {
        return 'splash animate-out-splash'
      } 
      return 'splash'
    }

    return(
      <div className={displayClass()}>
        <img src={Logo} alt="" />
      </div>
    )
  }
}