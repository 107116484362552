import React, {Component} from 'react'
import * as config from '../../App/configurations/Configs'

export default class StateLogo extends Component {

    render() {
        return (
            <div className='d-flex state-logo-container'>
                <img className="state-logo" src={config.DATA.HOST + 'images/' + this.props.logo} alt=""/>
                <h5>{this.props.name}</h5>
            </div>
        )
    }
}