import React, { Component } from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import * as utils from '../../Utilities/Utils'

class ListScreenContentLeft extends Component {

  constructor(props) {
    super(props)
    this.JSONObj = null
  }

  render() {

    // create a const that is a copy of the json object in state
    const JSONTemp = utils.getJSON(this.props.json, this.JSONObj)

    const renderTitleBody = () => {
      if (JSONTemp !== null) {
        const screen = JSONTemp.screens.HOUSE_LIST_VIEW.left_column
        return utils.renderTitleBody(screen.title, null, screen.body)
      }
    }

    const renderInputForm = () => {
      if (JSONTemp !== null) {
        const screen = JSONTemp.screens.HOUSE_LIST_VIEW.left_column.form
        return utils.renderInputForm(screen)
      }
    }

    return(
      <div className="list-content-left-column">
        {renderTitleBody()}
        {renderInputForm()}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    activeIcons: state.getIn(['house', 'activeIcons']),
    json: state.getIn(['house', 'json'])
  }
}

const mapDispatchToProps = dispatch => {
  return {

  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListScreenContentLeft))