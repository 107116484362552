import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import * as ActionCreators from '../ActionCreators'
import * as utils from '../../Utilities/Utils'
import LeftColumn from '../../App/containers/LeftColumn'
import RightColumn from '../../App/containers/RightColumn'
import HouseOpen from '../../images/Background-House_Open.svg'
import LeftColumnInnerContainer from './LeftColumnInnerContainer'
import Loader from '../../App/components/Loader'
import {TransformWrapper, TransformComponent} from 'react-zoom-pan-pinch'

class InnerViewContainer extends Component {

  constructor(props) {
    super(props)
    this.JSONObj = null
    this.state = {
      componentLoaded: false
    }
  }

  componentDidMount() {
    setTimeout(function() {
      this.setState({
        componentLoaded: true
      })
    }.bind(this), 500)
  }

  render() {

    // create a const that is a copy of the json object in state
    const JSONTemp = utils.getJSON(this.props.json, this.JSONObj)

    // renders particle components with JSON data
    const renderParticleSystems = () => {
      if (JSONTemp !== null) {
        const screen = JSONTemp.screens.HOUSE_INNER_VIEW
        if (screen.particle_systems) {
          return utils.renderParticleSystems(screen.particle_systems)
        }
      }
    }

    // renders state logo image with JSON data
    const renderStateLogo = () => {
      if (JSONTemp !== null) {
        const logo = JSONTemp.global_variables
        const findState = logo.states[logo.default_state]
        return utils.renderStateLogo(findState)
      }
    }

    // renders logo image with JSON data
    const renderImage = () => {
      if (JSONTemp !== null) {
        const logo = JSONTemp.global_variables
        return utils.renderImage(logo.logo_white, 'logo')
      }
    }

    // renders dropdown component with JSON data
    const renderDropdown = () => {
      if (JSONTemp !== null) {
        const dropdown = JSONTemp.global_variables
        return utils.renderDropdown(dropdown.dropdown)
      }
    }

    // renders icon components with JSON data
    const renderHouseIcons = (setTransform, resetTransform) => {
      if (JSONTemp !== null) {
        const iconsArray = Object.entries(JSONTemp.icons)
        const globalVar = JSONTemp.global_variables.setTransform
        const domElements = []
        // loop through the icons object and push each houseIcon to a new array that is returned 
        iconsArray.forEach((value) => {
          domElements.push(utils.renderHouseIcon(value[0], value[1], setTransform, resetTransform, this.props.dispatchSetResetTransformHook, globalVar))
        })
        return domElements 
      }
    }

    // renders loading screen component with JSON data
    const renderLoader = () => {
      if(JSONTemp !== null) {
        const delay = JSONTemp.global_variables
        return (
          <Loader 
            load={this.state.componentLoaded} 
            opacity={delay.load_screen_fade_opacity_delay}
            visibility={delay.load_screen_fade_visibility_delay} 
          />
        )
      }
    }

    return (
      <div className="full-height-columns">
        <LeftColumn>
          <div>
            <div className="logo-state-container">{renderImage()}{renderStateLogo()}</div>
            <LeftColumnInnerContainer/>
          </div>
        </LeftColumn>
        <RightColumn>
          <div className="right-column-inner-house-container">
            {renderLoader()}
            {renderParticleSystems()}
            <TransformWrapper
              wheel={{wheelEnabled: false}}
              doubleClick={{disabled: true}}
              pan={{disabled: true}}
              pinch={{disabled: true}}
            >
              {({resetTransform, setTransform, ...rest}) => (
                  <Fragment>
                    <TransformComponent>
                      <img className="open-house" src={HouseOpen} alt=""/>
                    </TransformComponent>
                    <div className="testy">{renderDropdown()}{renderHouseIcons(setTransform, resetTransform)}</div>
                  </Fragment>
                )}
            </TransformWrapper>
          </div>
        </RightColumn>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    json: state.getIn(['house', 'json'])
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatchSetResetTransformHook: (hook) => dispatch(ActionCreators.setResetTransformHook(hook))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InnerViewContainer))