import React, {Component, Fragment} from 'react'
import StartScreenContainer from './StartScreenContainer'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import * as Enums from '../Enums'
import MainViewContainer from './MainViewContainer'
import InnerViewContainer from './InnerViewContainer'
import ListViewContainer from './ListViewContainer'
import StateSelectContainer from './StateSelectContainer'
import * as utils from '../../Utilities/Utils'

class HouseContainer extends Component {

  constructor(props) {
    super(props)
    this.JSONObj = null
  }

  render() {

    // create a const that is a copy of the json object in state
    const JSONTemp = utils.getJSON(this.props.json, this.JSONObj)

    const renderThankYouContainer = () => {
      if (JSONTemp !== null) {
        const screen = JSONTemp.global_variables
        return utils.renderThankYouContainer(screen)
      }
    }

    // determines which component is loaded based on currentScreen state
    const displayScreen = () => {
      if (this.props.currentScreen === Enums.STATE_SELECT_VIEW) {
        return (
          <StateSelectContainer />
        )
      } else if (this.props.currentScreen === Enums.HOUSE_START_SCREEN) {
        return (
          <StartScreenContainer />
        )
      } else if (this.props.currentScreen === Enums.HOUSE_MAIN_VIEW) {
        return (
          <Fragment>
            {/* {this.props.screenLoaded === false ? <Loader /> : null } */}
            <MainViewContainer />
          </Fragment>
        )
      } else if (this.props.currentScreen === Enums.HOUSE_LIST_VIEW) {
        return (
          <ListViewContainer />
        )
      } else if (this.props.currentScreen === Enums.HOUSE_THANKS_VIEW) {
        return (
          renderThankYouContainer()
        )
      } else {
        return (
          <InnerViewContainer />
        )
      } 
    }

    return(displayScreen())

    }
}

const mapStateToProps = (state, ownProps) => {
  return {
    currentScreen: state.getIn(['house', 'currentScreen']),
    json: state.getIn(['house', 'json'])
  }
}

const mapDispatchToProps = dispatch => {
  return {
    
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HouseContainer))