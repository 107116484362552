import React, { Component } from 'react'
import * as config from '../../App/configurations/Configs'

export default class StateIcon extends Component {

  render() {

    return(
      <div className="state-icon">
        <img src={config.DATA.HOST + 'images/' + this.props.image} alt=""/>
        <h6>{this.props.text}</h6>
      </div>
    )
  }
}