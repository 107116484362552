import React, { Component } from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import * as utils from '../../Utilities/Utils'

class MainScreenContentLeft extends Component {

  constructor(props) {
    super(props)
    this.JSONObj = null
  }

  render() {

    // create a const that is a copy of the json object in state
    const JSONTemp = utils.getJSON(this.props.json, this.JSONObj)

    const renderInsideContentContainer = () => {
      if (JSONTemp !== null) {
        const screen = JSONTemp.screens.HOUSE_MAIN_VIEW.left_column
        return [
          utils.renderTitleBody(screen.title, null, screen.body),
          utils.renderCtaButton(screen.button)
        ]
      }
    }

    return(
      <div className="mainscreen-content-left-wrapper">
        {renderInsideContentContainer()}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
      json: state.getIn(['house', 'json'])
  }
}

const mapDispatchToProps = dispatch => {
  return {

  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainScreenContentLeft))