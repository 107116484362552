import React, { Component } from 'react';
import HouseContainer from '../../House/containers/HouseContainer';

export default class Body extends Component {

  render() {

    return(
      <div className="body-wrapper">
        <HouseContainer />
      </div>
    )
  }

}