import React, { Component, Fragment } from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import * as utils from '../../Utilities/Utils'

var uuid = require('uuid/v4')

class Form extends Component {

  constructor(props) {
    super(props)
    this.JSONObj = null
  }  

  render() {

    // create a const that is a copy of the json object in state
    const JSONTemp = utils.getJSON(this.props.json, this.JSONObj)

    // function to check which icons have been clicked and assigning their values as this data is passed during form submission
    const getActiveIcons = () => {
      // get an object returning the keys of another object
      const iconsArray = Object.keys(JSONTemp.icons)
      return iconsArray.map((key) => {
        if (this.props.activeIcons.isEmpty()) {
          return <input key={uuid()} type="hidden" name={key} value="N" />
        } else if (this.props.activeIcons.has(key)) {
          return <input key={uuid()} type="hidden" name={key} value="Y" />
        } else 
          return <input key={uuid()} type="hidden" name={key} value="N" />
      })
    }

    const renderCtaButton = () => {
      if (JSONTemp !== null) {
        const screen = JSONTemp.screens.HOUSE_LIST_VIEW.left_column
        return utils.renderCtaButton(screen.button)
      }
    }

    return(
      <Fragment>
        <p className="email-title">{this.props.title}</p>
        <form id="submit-form" action={this.props.formAction} method="post">
          <input type="hidden" name="_clientID" value={this.props._clientID} />
          <input type="hidden" name="_deExternalKey" value={this.props._deExternalKey} />
          <input type="hidden" name="_action" value={this.props._action} />
          <input type="hidden" name="_returnXML" value={this.props._returnXML} />
          <input type="hidden" name="_successURL" value={this.props._successURL} />
          <input type="hidden" name="_errorURL" value={this.props._errorURL} />
          {getActiveIcons()}
          <input id="emailInput" name="email" placeholder={this.props.placeholder} />
          <div id="emailError"></div>
          <br></br>
          {renderCtaButton()}
        </form>
      </Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    activeIcons: state.getIn(['house', 'activeIcons']),
    json: state.getIn(['house', 'json'])
  }
}

const mapDispatchToProps = dispatch => {
  return {

  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Form))