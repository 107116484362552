import * as ActionTypes from './ActionTypes'
import * as Enums from './Enums'
import {Map} from 'immutable'

const getInitialState = () => {
  return Map({
    // currentScreen: Enums.STATE_SELECT_VIEW,
    currentScreen: Enums.HOUSE_START_SCREEN,
    // currentScreen: Enums.HOUSE_MAIN_VIEW, 
    // currentScreen: Enums.HOUSE_INNER_VIEW,
    // currentScreen: Enums.HOUSE_LIST_VIEW,
    resetZoom: 0,
    selectedState: Enums.HOUSE_STATE,
    showIcons: true,
    activeIcons: Map({}),
    showModal: false,
    resetTransformHook: 0,
    json: Map({})
  })
}

export function reducer(state = getInitialState(), action) {
  switch (action.type) {
    case ActionTypes.HOUSE_SET_SCREEN:
      return state.set('currentScreen', action.screen)
    case ActionTypes.SET_ACTIVE_ICON:
      return state.set('activeIcons', Map(action.icons))
    case ActionTypes.LOAD_ICON_DETAIL:
      return state.set('clickedIcon', action.icon)
    case ActionTypes.RESET_ZOOM:
      return state.set('resetZoom', action.num)
    case ActionTypes.SHOW_ICONS:
      return state.set('showIcons', action.boolean)
    case ActionTypes.SHOW_MODAL:
      return state.set('showModal', action.boolean)
    case ActionTypes.SET_RESET_TRANSFORM_HOOK:
      return state.set('resetTransformHook', action.hook)
    case ActionTypes.SET_JSON_DATA:
      return state.set('json', Map(action.data))
    default:
      return state
  }
}
