import React, {Component} from 'react'

export default class Footer extends Component {

    render() {
        return (
            <div className="footer-bar">
                <div className="blue-bar"></div>
                <div className="spacer-bar"></div>
                <div className="light-green-bar"></div>
                <div className="spacer-bar"></div>
                <div className="dark-green-bar"></div>
            </div>
        )
    }
}