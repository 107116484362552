import React, { Component } from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import anime from 'animejs/lib/anime.es.js'
import * as config from '../configurations/Configs'


class ParticleAnimation extends Component {

  // Component for handling animation of particles on screen

  constructor(props) {
    super(props)
    this.currentTime = 0
    this.nextSpawnTime = 0
    this.timer = null
  }

  // function for spawning particles
  spawn() {
    const image = document.createElement('img')
    const element = document.getElementById('svgContainer')

    // set source of image within the array that is provided
    image.src = config.DATA.HOST + 'images/' + this.props.images[Math.floor(Math.random() * this.props.images.length)]
    image.className = 'particle'
    image.style.left = '0'
    image.style.top = this.getSpawnLocationY()
    element.appendChild(image)
    if (this.props.direction === 'right') {
      image.style.zIndex = '10'
    } else {
      image.style.zIndex = '0'
      image.style.width = this.getSvgSize()
    }
    // animation library call
    anime({
      targets: image,
      translateX: this.getDirection(),
      easing: 'linear',
      loop: false,
      duration: this.getDuration(),
      complete: function(anim) {
        if (element !== null) {
          element.removeChild(image)
        }
      }
    })
  }  

  // function for getting random int within a range
  getRandomInt(min, max) {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min)) + min; 
  }

  // function for getting a random spawn time for spawned particle
  getNewSpawnTime() {
    this.nextSpawnTime = this.getRandomInt(this.props.spawnTimeMin, this.props.spawnTimeMax) * 1000
  }

  // function for getting a random Y position for spawned particle
  getSpawnLocationY() {
    const yPos = this.getRandomInt(this.props.yMin, this.props.yMax)
    return yPos + 'px'
  }

  // function for getting a random animation duration for spawned particle
  getDuration() {
    return this.getRandomInt(this.props.durationMin, this.props.durationMax)
  }

  // function for determining animation direction of spawned particle
  getDirection() {
    const windowWidth = window.innerWidth
    if (this.props.direction === 'right') {
      return [windowWidth, -200]
    } else if (this.props.direction === 'left') {
      return [-200, windowWidth]
    }  
    const getDirection = this.getRandomInt(0, 2)
    if (getDirection === 0) {
      return [windowWidth, -200]
    } 
    return [-200, windowWidth]
  }
 
  // function for getting sizes of spawned particle
  getSvgSize() {
    return this.props.sizes[Math.floor(Math.random() * this.props.sizes.length)] + 'px'
  }

  runSpawn() {
    this.getNewSpawnTime()
    // create timer. once currentTime is > nextSpawnTime then create new particle and reset timer
    this.timer = setInterval((function(){
      this.currentTime += 100
      if (this.currentTime >= this.nextSpawnTime) {
        this.spawn()
        this.currentTime = 0
        this.getNewSpawnTime()
      }
    }).bind(this), 100)
  }

  componentDidMount() {
    this.spawn()
    this.runSpawn()
    // add event listener for detecting screen visibility
    document.addEventListener('visibilitychange', () => {
      // clear timer and do not create new particle spawn if screen is not visible
      // if screen is visible, resume normal particle spawn function
      if (document.visibilityState === 'hidden') {
        clearInterval(this.timer)
      } else if (document.visibilityState === 'visible') {
        clearInterval(this.timer)
        this.runSpawn()
      }
    })
    
  }

  componentWillUnmount() {
    // clear timer when component is removed to prevent errors
    clearInterval(this.timer)
  }
  
  render() {

    return(
      <div id="svgContainer"></div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    currentScreen: state.getIn(['house', 'currentScreen'])
  }
}

const mapDispatchToProps = dispatch => {
  return {

  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ParticleAnimation))