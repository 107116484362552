import React, { Component } from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import MainScreenContentLeft from '../components/MainScreenContentLeft'
import InnerScreenContentLeft from '../components/InnerScreenContentLeft'
import DetailScreenContentLeft from '../components/DetailScreenContentLeft'
import ListScreenContentLeft from '../components/ListScreenContentLeft'
import * as Enums from '../Enums'
// import * as data from '../../data.json' 

class LeftColumnInnerContainer extends Component {

  render() {

    // display the component depending on screen state or passed prop
    const displayContent = () => {
      if (this.props.currentScreen === Enums.HOUSE_MAIN_VIEW) {
        return (
          <MainScreenContentLeft />
        ) 
      } else if (this.props.resetZoom === 0) {
        return (
          <InnerScreenContentLeft />
        ) 
      } else if (this.props.clickedIcon) {
        return (
          <DetailScreenContentLeft />
        )
      } else if (this.props.currentScreen === Enums.HOUSE_LIST_VIEW) {
        return (
          <ListScreenContentLeft />
        )
      } else {
        return (
          <InnerScreenContentLeft />
        ) 
      }
    }

    return(displayContent())
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    currentScreen: state.getIn(['house', 'currentScreen']),
    clickedIcon: state.getIn(['house', 'clickedIcon']),
    resetZoom: state.getIn(['house', 'resetZoom'])
  }
}

const mapDispatchToProps = dispatch => {
  return {
    
  }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeftColumnInnerContainer))
