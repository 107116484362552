import React, {Component} from 'react'
import * as ActionCreators from '../ActionCreators'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import * as Enums from '../Enums'
// import StateLogo from './StateLogo'
class HouseIcon extends Component {

  iconClick() {
    if (this.props.currentScreen === Enums.HOUSE_INNER_VIEW) {
      this.props.dispatchShowIcons(false).then(() => {
        this.props.zoomLocation()
      })
      this.props.dispatchSetClicked(this.props.iconKey)
      this.props.dispatchAddIcon(this.props.activeIcons, this.props.iconKey)
      this.props.dispatchResetZoom(1)
    }
  }

  render() {
    // set inline css style upon checking if showIcons exists
    const style = !this.props.showIcons ? {display: 'none'} : {transform: `translate(${this.props.xTransform}px, ${this.props.yTransform}px)`}

    const iconDisplay = () => {
      if (!this.props.activeIcons.has(this.props.iconKey)) {
        return(
          // display blueIcon if icon is not inside activeIcons
          <img 
            onClick={this.iconClick.bind(this)} 
            className={this.props.iconClass} 
            style={style}
            src={this.props.blueIcon} 
            alt=""
          />
        )
      } else {
        return(
          // display greenIcon if icon is inside activeIcons
            <img 
              onClick={this.iconClick.bind(this)} 
              className={this.props.iconClass} 
              style={style}
              src={this.props.greenIcon} 
              alt=""
            />
        )
      }
    }
      
    return(iconDisplay())
    
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    currentScreen: state.getIn(['house', 'currentScreen']),
    activeIcons: state.getIn(['house', 'activeIcons']),
    clickedIcon: state.getIn(['house', 'clickedIcon']),
    showIcons: state.getIn(['house', 'showIcons'])
  }
}

const mapDispatchToProps = dispatch => {
  return {
      dispatchSetScreen: (screen) => dispatch(ActionCreators.setScreen(screen)),
      dispatchAddIcon: (prevState, icon) => dispatch(ActionCreators.addIcon(prevState, icon)),
      dispatchSetClicked: (icon) => dispatch(ActionCreators.setClicked(icon)),
      dispatchResetZoom: (num) => dispatch(ActionCreators.resetZoom(num)),
      dispatchShowIcons: (boolean) => dispatch(ActionCreators.showIcons(boolean))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HouseIcon))