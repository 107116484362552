import React, {Component} from 'react'

export default class RightColumn extends Component {

  render() {
    return (
      <div className="right-column-container">
        {this.props.children}
      </div>
    )
  }
}