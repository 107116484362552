import React, { Component } from 'react'
import DidYouKnow from './DidYouKnow'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import * as utils from '../../Utilities/Utils'

var uuid = require('uuid/v4')

class InnerScreenContentLeft extends Component {

  constructor(props) {
    super(props)
    this.JSONObj = null
  }

  render() {

    // create a const that is a copy of the json object in state
    const JSONTemp = utils.getJSON(this.props.json, this.JSONObj)

    const renderDidYouKnow = () => {
      if (JSONTemp !== null) {
        // grab the data in the object based on which icon was clicked
        const iconObj = JSONTemp.icons[this.props.clickedIcon]
        return <DidYouKnow 
                key={uuid()} 
                text={iconObj.did_you_know} 
               />
      }
    }

    const renderTitleBody = () => {
      if (JSONTemp !== null) {
        const screen = JSONTemp.screens.HOUSE_INNER_VIEW
        return utils.renderTitleBody(screen.left_column.title, null, screen.left_column.body)
      }
    }

    if (this.props.activeIcons.size === 0) {
      return (
        <div>
          {renderTitleBody()}
        </div>
      )
    } else {
      return (
        <div>
          {renderTitleBody()}
          {renderDidYouKnow()}
        </div>
      )
    }
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
    activeIcons: state.getIn(['house', 'activeIcons']),
    json: state.getIn(['house', 'json']),
    clickedIcon: state.getIn(['house', 'clickedIcon'])
  }
}

const mapDispatchToProps = dispatch => {
  return {
    
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InnerScreenContentLeft))