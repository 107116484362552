import React, { Component } from 'react'
import * as ActionCreators from '../../House/ActionCreators'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import * as config from '../configurations/Configs'
import axios from 'axios'

class JSONLoader extends Component {

  // Component loads our JSON data into state
  
  constructor(props) {
    super(props)
    axios.get(config.DATA.HOST + 'data.json')
    .then(res => {
      props.dispatchLoadJSON(res.data)
    })
  }

  render() {
    return(
      <div></div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {

  }
}

const mapDispatchToProps = dispatch => {
  return {
      dispatchLoadJSON: (json) => dispatch(ActionCreators.loadJSON(json))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(JSONLoader))