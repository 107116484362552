import React from 'react'
import Immutable from 'immutable'
import { render } from 'react-dom'
import Root from './App/Root'
import configureStore from './App/store/configureStore'
import 'bootstrap/dist/css/bootstrap.min.css';
import './fonts/SlatePro-Bold.otf'
import './fonts/SlatePro-BoldItalic.otf'
import './fonts/SlatePro-Italic.otf'
import './fonts/SlatePro-Light.otf'
import './fonts/SlatePro.otf'

const initialState = Immutable.Map();
const store = configureStore(initialState)

render(
  <Root store={store} />,
  document.getElementById('root')
)