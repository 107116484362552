import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import * as utils from '../../Utilities/Utils'

var uuid = require('uuid/v4')

class DidYouKnow extends Component {

  constructor(props) {
    super(props)
    this.JSONObj = null
  }

  render() {

    // create a const that is a copy of the json object in state
    const JSONTemp = utils.getJSON(this.props.json, this.JSONObj)

    const renderInsideContentContainer = () => {
      if(JSONTemp !== null) {
        const screen = JSONTemp.screens.HOUSE_INNER_VIEW.left_column
        return [
          <div key={uuid()} className="bubble">{screen.did_you_know}</div>,
          <p key={uuid()} className="did-you-know">{this.props.text}</p>,
          utils.renderCtaButton(screen.button)
        ]
      }
    }

    return(
      <Fragment>
        {renderInsideContentContainer()}
      </Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    json: state.getIn(['house', 'json'])
  }
}

const mapDispatchToProps = dispatch => {
  return {

  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DidYouKnow))