import React, { Component } from 'react'
import * as ActionCreators from '../../House/ActionCreators'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import ReactPlayer from 'react-player'
import VideoPlayer from 'react-video-js-player'

class VideoModal extends Component {

  closeModal() {
    this.props.dispatchShowModal(false)
  }

  render() {

    const showModal = () => {
      if(this.props.showModal) {
        return(
          <div className="video-modal-popup">
            <div className="video-wrapper">
              <VideoPlayer
                controls={true}
                src={this.props.url}
                // poster={this.state.video.poster}
                width="1000"
                height="563"
              />
              <div onClick={this.closeModal.bind(this)} className="video-modal-close-btn">X</div>
            </div>
          </div>
        )
      } else return null
    }

    return(showModal())
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    showModal: state.getIn(['house', 'showModal'])
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatchShowModal: (boolean) => dispatch(ActionCreators.showModal(boolean))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VideoModal))
