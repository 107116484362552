import React, {Component} from 'react'
import * as ActionCreators from '../ActionCreators'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import * as Enums from '../Enums'
import LeftColumn from '../../App/containers/LeftColumn'
import RightColumn from '../../App/containers/RightColumn'
import ListScreenContentLeft from '../components/ListScreenContentLeft'
import * as utils from '../../Utilities/Utils'

var uuid = require('uuid/v4')

class ListViewContainer extends Component {

  constructor(props) {
    super(props)
    this.JSONObj = null
  }

  buttonClick() {
      this.props.dispatchSetScreen(Enums.HOUSE_THANKS_VIEW)
  }

  render() {

    // create a const that is a copy of the json object in state
    const JSONTemp = utils.getJSON(this.props.json, this.JSONObj)

    const renderListItem = () => {
      if (JSONTemp !== null) {
        const iconsArray = Object.entries(JSONTemp.icons)
        const domElements = []
        // loop through the icons object and push each list item to a new array that is returned 
        iconsArray.forEach((value) => {
          domElements.push(
            <div key={uuid()} className="d-flex align-items-center icon-wrapper">
              {utils.renderListHouseIcon(value[0], value[1])}
              {utils.renderListIconDescription(value[0], value[1])}
            </div>
          )
        })
        return domElements
      }
    }

    const renderStateLogo = () => {
      if (JSONTemp !== null) {
        const logo = JSONTemp.global_variables
        const findState = logo.states[logo.default_state]
        return utils.renderStateLogo(findState)
      }
    }

    const renderTitleBody = () => {
      if (JSONTemp !== null) {
        const screen = JSONTemp.screens.HOUSE_LIST_VIEW.right_column
        return utils.renderTitleBody(screen.title, null, screen.body)
      }
    }

    const renderImage = () => {
      if (JSONTemp !== null) {
        const screen = JSONTemp.global_variables
        return utils.renderImage(screen.logo_white, 'logo')
      }
    }
    
    return (
      <div className="full-height-columns">
        <LeftColumn>
          <div>
            <div className="logo-state-container">{renderImage()}{renderStateLogo()}</div>
            <ListScreenContentLeft />
          </div>
          
        </LeftColumn>
        <RightColumn>
          <div className="list-wrapper">
            {renderTitleBody()}
            <div className="energy-list-container">
              {renderListItem()}
            </div>
          </div>
        </RightColumn>
      </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
  return {
    json: state.getIn(['house', 'json'])
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatchSetScreen: (screen) => dispatch(ActionCreators.setScreen(screen))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListViewContainer))